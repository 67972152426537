import { useEffect, useState } from 'react';
import './style/style.scss';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";


function fetchGllery(offset: number = 0){
  return fetch(`https://api.zabawkistryszawskie.pl/wp-json/wp/v2/gallery?per_page=1&offset=${offset}`);
  // return fetch(`http://localhost/zabawki/index.php/wp-json/wp/v2/gallery?per_page=1&offset=${offset}`);
}

function App() {
  const [gallery, setGallery] = useState([]);
  const [offset, setOffset] = useState(1);
  const [isBtnVisible, setBtnVisible] = useState(true);

  async function fetchMore(){
    const rq = await fetchGllery(offset);
    const headers: any = rq.headers;
    
    if(headers.get('X-WP-TotalPages') <= offset+1){
      setBtnVisible(false);
    }

    const response = await rq.json();

    setGallery(state => state.concat(response[0].acf.gallery));
    setOffset(state => state+1)
  }

  useEffect(() => {
    fetchGllery().then(rq => rq.json()).then((response) => {
      setGallery(response[0].acf.gallery);
    })
  }, []); 


  return (
    <section className="section" id="galeria">
      <div className="container">
        <h1 className="section__header">Galeria</h1>
        <h2 className="section__subheader">Poznaj Zabawki Stryszawskie</h2>
        <SimpleReactLightbox>
          <SRLWrapper>
            <ul className="gallery grid grid--col-4">
              {gallery?.map((figure: any) => {
                return (
                  <li key={figure.image.id} className="gallery__item">
                    <a href={figure.image.url} data-attribute="SRL">
                      <figure className="relative gallery__figure">
                        <img src={figure.image.sizes.medium_large} alt={figure.image.alt} className="img gallery__item" loading="lazy" />            
                        <figcaption className="gallery__caption">{figure.caption}</figcaption>
                      </figure>
                    </a>
                  </li>
                )
              })}
            </ul>
          </SRLWrapper>
      </SimpleReactLightbox>

        {isBtnVisible  && 
          <div className="text-center mgt-70">
            <button className="btn" onClick={fetchMore}>Zobacz więcej</button>
          </div>
        }
      </div>
    </section>  
  );
}

export default App;
 